/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */

$mainColor: #020B1E;
$mainColor2: #1f2331;
$mainColor3: #2b2d3d;
$mainColor4: #2d3140;
$mainColor5: #485e78;
$mainColor6: #2b2f3e;
body {
  width: 100%;
  position: relative;
  font-family: Arial;
  background: $mainColor;

  &.h5 {
    .mask {
      background-color: $mainColor;
    }

    #Share_details {

      // background: $mainColor;

      #Avatar .avatarLogo {
        background-color: $mainColor2;
      }

      .item_shell {
        .red {
          background-color: #c72c3b
        }

        .yellow {
          background-color: #c56b2a
        }

        .blue {
          background-color: #116397
        }
      }

      .centent, #scoring_details .box_bg_multiple {
        background-color: $mainColor2;
      }

      .ComprehensiveAnalysisResults .centent_text {
        background-color: $mainColor3;
      }

      #Skin_question .item_shell {
        .centent {
          background-color: $mainColor2;

          .skin_question_avatar {
            background-color: $mainColor4;

            .skin_question_model_box {
              &.colorSkin .colorSkinContent {
                background-color: $mainColor3;
              }

              & > span {
                background-color: $mainColor5;
              }
            }
          }

          .symptoms_nursing .goods_list .goods {
            // background-color: $mainColor6;
          }
        }

        &:last-child {
          .centent .skin_question_avatar {
            //background-color: $mainColor2;
          }
        }
      }
    }
  }
}

img:not(.btn,.Bounced_Delete,.canvas_img,.skin_question_avatar_img,.PhotoView__Photo,.pay_icon) {
  pointer-events: none
}

;
::-webkit-scrollbar {
  display: none;
}

.line-clamp-six {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.progress {
  background: #3c3c3c;
  //border:2px solid #3c3c3c;
  //border:2px solid #000;
  width: 5.250rem;
  height: 0.333rem;
  overflow: hidden;
  border-radius: 0.167rem;
  -webkit-appearance: none;
}

::-ms-fill {
  background: #fafafa;
}

::-moz-progress-bar {
  background: #fafafa;
}

::-webkit-progress-bar {
  background: #3c3c3c;
}

::-webkit-progress-value {
  background: #fafafa;
  border-radius: 0.167rem;
  overflow: hidden;
}

.btnActive {
  opacity: 0.8;
  margin-top: 0.200rem;
}

#index, #test_page, #inspectionRecords {
  .title {
    position: fixed;
    top: 0rem;
    width: 100%;
    height: 2.445rem;
    line-height: 2.445rem;
    text-align: center;
    //font-family: SourceHanSansCN-Regular;
    font-size: 0.833rem;
    font-weight: bold;
    letter-spacing: 0.083rem;
    color: #ececec;
    box-shadow: 0rem 0.042rem 0rem 0rem rgba(255, 255, 255, 0.2);
    background-color: #040920;
  }
}

#index, #test_page, #inspectionRecords, #Share_details {
  //pointer-events: none;

  -webkit-touch-callout: none; /*系统默认菜单被禁用*/

  -webkit-user-select: none; /*webkit浏览器*/

  -khtml-user-select: none; /*早起浏览器*/

  -moz-user-select: none; /*火狐浏览器*/

  -ms-user-select: none; /*IE浏览器*/

  user-select: none; /*用户是否能够选中文本*/
}

// * { touch-action: pan-y; }