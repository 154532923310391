#Share_details {
  -webkit-overflow-scrolling: touch;
  // position: absolute;
  background: #020B1E;
  width: 100%;
  // height:100%;
  //overflow-y: scroll;
  .share-icon {
    width: 3.077rem;
    position: fixed;
    right: 0.833rem;
    bottom: 2.833rem;
    z-index: 30000;

    &.to-top {
      width: 2.69rem;
      height: 2.69rem;
      //background:rgba(108,157,233,1);
      opacity: 0.5;
      border-radius: 1rem;
      padding: 1.03rem 0.78rem;
      box-sizing: border-box;
    }
  }

  section {
    width: calc(100% - 2.222018611333333rem);
    margin: auto;
  }

  #Avatar {
    width: 100%;
    height: 12.677rem;
    //margin: 1.75rem auto 0;
    //background-image: url("../img/avatar.png");
    background-position: center center;
    background-size: 100% 100%;
    position: relative;

    .reportTopFlow {
      width: 100%;
      //margin-left: -1.6665rem;
      position: relative;
      z-index: 1;
    }

    .cusLogo {
      position: absolute;
      top: 1.47rem;
      left: 0.83rem;
      z-index: 999;
      width: 5.19rem;

      img {
        width: 100%
      }
    }

    .instrumentLogo {
      position: absolute;
      top: 5.333rem;
      left: 1.311rem;
      width: 3.000rem;
      z-index: 999;
      //height: 5.25rem;
    }

    .avatarBox {
      position: absolute;
      top: 5.333rem;
      left: 50%;
      z-index: 999;
      transform: translateX(-50%);
    }

    .avatarLogo {
      //top: 10.625rem;
      //left: 12.125rem;
      //width: 5.958rem;
      //height: 6.292rem;
      width: 6.000rem;
      height: 6.000rem;
      margin: 0.333rem;
      //background-color: #4cc4fc;
      text-align: center;
      border-radius: 50%;
      //background-color: #1f2331;
      overflow: hidden;

      img {
        margin-top: 0.800rem;
        width: 4.195rem;
        height: 4.195rem;
        border-radius: 50%;
        border: solid 0.111rem #4f536c;
      }
    }

    //.pointer{
    //  width: 8.583rem;
    //  position: absolute;
    //  top: 2rem;
    //  left: 9.583rem;
    //  //transform: rotate(-240deg);
    //}
    .svg {
      //position: absolute;
      //top: 5.333rem;
      //left: 6.861rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 6.667rem;
      height: 6.667rem;
      transform: rotate(-90deg);

      circle {
        r: 3.073rem;
        cx: 3.333rem;
        cy: 3.333rem;
        stroke-width: 6;

        &.progress_circle {
          stroke-width: 6;
          animation: strokeDasharray 3.5s;
        }
      }
    }

    .totalScore {
      font-size: 1.111rem;
      font-weight: bold;
      letter-spacing: 0.133rem;
      color: #ececec;
      position: absolute;
      top: 6.555rem;
      left: 8.639rem;
    }
  }

  #scoring_details {
    //div{
    //padding-bottom: 0.333rem;
    //background-color: #2b2d3d;
    border-radius: 0.555rem;
    //margin: -1.533rem auto 0;
    //width: 18.611314722rem;
    position: relative;
    z-index: 555;

    .box_bg_multiple {
      margin: -1.533rem auto 0;
      height: 3.467rem;
      //background-color: #1f2331;
      border: solid 0.1rem #373C4A;

      img {
        top: 0;
      }

      .text_box {
        height: 0.833rem;

        .text {
          line-height: 1.2;
          margin-top: -0.417rem;
        }
      }
    }

    .box_bg_single {
      margin-bottom: 0.5rem;

      img {
        top: 50%;
        transform: translateY(-50%);
      }

      .text_box {
        .name {
          margin-right: 0.58rem;
        }

        .text {
          display: flex;
          flex-flow: row-reverse;
          justify-content: center;
          align-items: flex-end;
          //margin-top: 0.417rem;
          font-size: 1.11rem;
        }
      }
    }

    .box_bg {
      //width: calc(100% - 0.2rem);
      border-radius: 0.555rem;
      display: flex;
      align-items: center;
      position: relative;

      .text_box {
        flex: 1;
        display: inline-block;
        //font-family: SourceHanSansCN-Regular;
        font-size: 0.833rem;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0.15rem;
        color: #ececec;
        text-align: center;
        //height: 0.833rem;
        //line-height: 0.5rem;
        //width: 33%;
        &:not(:last-of-type) {
          //border-left: solid 0.117rem rgba(89, 89, 89, 0.5);
          border-right: solid 0.117rem rgba(89, 89, 89, 0.5);
        }

        .name {
          font-size: 0.667rem;
          font-weight: bold;
          letter-spacing: 0.121rem;
          color: #999999;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .promptBtn {
        width: 0.988rem;
        position: absolute;
        right: 0;
      }
    }

  }

  .ComprehensiveAnalysisResults {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1.695rem auto 1.111rem;
      color: white;

      .line {
        width: 1.667rem;
        height: 0.167rem;
        display: inline-flex;
        //background-color: #116397;
      }

      .text {
        padding: 0 0.528rem;
        font-size: 0.778rem;
        line-height: 0.476rem;
        //margin: auto;
      }
    }

    .centent_text {
      width: 16.5rem;
      //max-height: 6.389rem;
      margin: 0 auto 1.695rem;
      padding: 1.111rem;
      //box-shadow: ;
      box-sizing: border-box;
      //background-color: #2b2d3d;
      border-radius: 0.555rem;
      font-size: 0.694rem;
      line-height: 1.167rem;
      letter-spacing: 0.058rem;
      color: #C0BFBF;
    }
  }

  .item_shell {
    // position: relative;
    text-align: center;

    .top_title_old {
      width: 7.04628rem;
      height: 1.665rem;
      //background: #116397;
      border-radius: 0.277722rem;
      margin: 0.74925rem auto 0;
      position: relative;
      z-index: 999;

      &::after {
        font-size: 0.8325rem;
        content: attr(data-after);
        width: 6.7932rem;
        height: 1.61172rem;
        line-height: 1.61172rem;
        //background: #116397;
        display: inline-block;
        border-radius: 0.277722rem;
        position: absolute;
        top: 0rem;
        left: 0.12654rem;
        color: white;
        text-align: center;

      }
    }

    .top_title {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 7.053rem;
      height: 1.667rem;
      //line-height: 2.5rem;
      //background: #116397;
      //display: inline-block;
      border-radius: 0.278rem;
      margin: 0.750rem auto 0;
      position: relative;
      //background: #116397;
      font-size: 0.833rem;
      padding: 0 0.833rem;
      color: white;
      box-sizing: border-box;
      z-index: 999;

      img {
        width: 1.2rem;
        margin-right: 0.250rem;
      }
    }

    .centent {
      width: 100%;
      text-align: left;
      border-radius: 0.555rem;
      margin: -1.3rem auto 0;
      background-size: 100%;
      // padding-bottom: 0.222rem;

      span.indicatorsShow {
        font-size: 0.667rem;
        line-height: 0.476rem;
        letter-spacing: 0.1rem;
        color: #73798f;
        display: inline-flex;
        align-items: center;
        float: right;
        margin: 0.56rem;

        img {
          margin-left: 0.476rem;
          width: 0.9833rem;
        }
      }
    }
  }

  #Skin_analysis {
    z-index: 2;

    .centent {
      background-image: url("../../static/img/grid-background.png");
      position: relative;

      .indicatorsShow {
        position: absolute;
        right: 0;
        top: 0;
      }

      .analysis_list {
        display: flex;
        flex-wrap: wrap;
        padding-top: 0.5rem;
        margin: 0.800rem auto;

        .analysis_item {
          color: white;
          display: flex;
          position: relative;
          margin: 1.611rem 0 0 1.111rem;
          min-width: calc(50% - 2rem);
          //box-sizing: content-box;
          img.problem_icon {
            width: 1.833rem;
            height: 1.833rem;
          }

          .degree_icon {
            position: absolute;
            top: -0.222rem;
            left: 1.222rem;
          }

          .instructions {
            display: flex;
            align-items: center;

            span.name {
              font-size: 0.611rem;
              line-height: 0.476rem;
              letter-spacing: 0.061rem;
              margin: 0 0.5rem;
              color: #999999;
            }

            span.degree {
              font-size: 0.889rem;
              color: #ffffff;
            }
          }

          figure {
            margin-left: 0.555rem;

            font-size: 0.611rem;
            color: #999999;

            .predicted {
              margin-left: 0.333rem;
              font-size: 0.889rem;
              color: #ffffff;
            }
          }
        }
      }

      .pie_analysis {
        position: relative;
        text-align: center;
        margin: auto;

        .canvas_text {
          position: absolute;
          z-index: 999;
        }

        .canvas_pie {
          position: relative;
          z-index: 555;
        }

        .canvas_bg {
          width: 8.945rem;
          //margin-top: -4.29rem;
          //position: relative;
          z-index: 1;
          position: absolute;
          top: 4.053rem;
          left: 4.833rem;
        }

        .canvas_title {
          font-size: 12px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 14px;
          position: absolute;
          left: 50%;
          bottom: 0.667rem;
          transform: translateX(-50%);
          display: block;
        }
      }
    }
  }

  #swiperBox {
    position: relative;
    margin-top: 1.83rem;

    .swiper-container {
      width: 15rem;

      .swiper-wrapper {
        margin-bottom: 2.86rem;
      }

      .swiper-pagination {
        margin-top: 2.86rem;
        height: 0.11rem;
      }
    }

    .left, .right {
      position: absolute;
      top: 6.14rem;
      color: #FFFFFF;
      background-color: transparent;
      background-size: 100% 100%;
      width: 0.89rem;
      height: 1.33rem;
      outline: 0;
      border: 0;

      &.left {
        left: 0;
        background-image: url("../../static/img/report_left_icon@2x.png");
      }

      &.right {
        right: 0;
        background-image: url("../../static/img/report_right_icon@2x.png");
      }
    }

    .swiper-bottom-img {
      position: absolute;
      bottom: 1rem;
      //margin-top: -5.14rem;
      margin-left: calc(-2.222018611333333rem / 2);
      width: 20.83rem;
      height: 7rem;
      background-size: 100% 100%;
      background-image: url("../../static/img/report_basemap@2x.png");
      //background: rgba(34, 36, 50, 1);
      .swiper-active-index {
        display: block;
        text-align: center;
        margin: 5.78rem auto;
        font-size: 0.61rem;
        font-weight: 400;
        color: rgba(93, 97, 115, 1);
      }
    }

    .swiper-slide {
      //width: 15.33rem;
      //height: 15.56rem;
      color: #FFFFFF;
      border-radius: 1rem;
      background-size: 100% 100%;
      background-color: rgba(0, 0, 0, 0);
      //display: flex;
      //justify-content: center;
      .swiper-slide-box {
        background: rgba(57, 61, 75, 1);
        border-radius: 1rem;
        //width:8.56rem;
        .name {
          font-size: 0.83rem;
          line-height: 2.73rem;
          font-weight: 400;
          text-align: center;
          color: rgba(254, 254, 254, 1);
          background-color: #1F2331;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }

        img {
          width: 100%;
          padding: 0.4rem;
          border-radius: 1rem;
          box-sizing: border-box;
          min-height: 10rem;
        }
      }
    }

    .swiper-pagination-progressbar {
      top: auto;
      bottom: 0;
    }

    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
      background: linear-gradient(-90deg, rgba(76, 196, 252, 1), rgba(81, 86, 210, 1), rgba(2, 11, 30, 1));
    }

    .swiper-slide.swiper-button-prev, .swiper-slide.swiper-button-active, .swiper-slide.swiper-button-next {
      //display: block !important;
    }

    .swiper-button-prev {
      //margin-left: 500px !important;
    }

    .swiper-button-next {
      //margin-right: 500px !important;
    }

    .swiper-button-next + div {

    }

    //.swiper-button-next, .swiper-button-prev {
    //  width: 1.78rem;
    //  height: 2.67rem;
    //  border: 0.06px solid rgba(233, 233, 233, 1);
    //
    //  &:after {
    //    content: ""
    //  }
    //}
    //
    //.swiper-button-next {
    //  background: linear-gradient(-30deg, rgba(30, 34, 45, 1), rgba(69, 73, 88, 1), rgba(42, 46, 63, 1), rgba(36, 40, 55, 1), rgba(60, 64, 78, 1));
    //}
    //
    //.swiper-button-prev {
    //  background: linear-gradient(150deg, rgba(30, 34, 45, 1), rgba(69, 73, 88, 1), rgba(42, 46, 63, 1), rgba(36, 40, 55, 1), rgba(60, 64, 78, 1));
    //}

    //.swiper-slide-prev,.swiper-slide-next{
    //  transform:scale(0.8)
    //}
    
  }

  #Skin_question {
    margin-top: 2rem;
    .item_shell {
      .centent {
        .skin_question_avatar {
          width: 100%;
          overflow: hidden;
          //background: #2d3140;
          position: relative;
          z-index: 1;

          .report_Icon_Badge {
            width: 3.889rem;
            position: absolute;
            top: 1.555rem;
            left: 0.500rem;
            z-index: 999;
          }

          .skin_question_model_box {
            width: 100%;
            text-align: center;
            margin: 2.445rem auto 1.389rem;
            box-sizing: content-box;

            & > img {
              width: 6.972rem;
            }

            & > span {
              position: absolute;
              border-radius: 0.653rem;
              border: solid 0.055rem #4cc4fc;
              font-size: 0.611rem;
              letter-spacing: 0.061rem;
              //padding: 0.333rem 0.444rem;
              //background: rgba(72, 94, 120, 1);
              z-index: 999;
              color: #fefefe;
            }

            &.spots span, &.pore span {
              top: 5.867rem;
              right: 12.587rem;
            }

            &.blain span {
              top: 3.867rem;
              right: 12.587rem;
            }

            &.blackhead span {
              top: 6.467rem;
              right: 12.587rem;
            }

            &.wrinkle span {
              &:nth-of-type(1) {
                top: 2.855rem;
                right: 12.717rem;
              }

              &:nth-of-type(2) {
                top: 7.253rem;
                right: 12.587rem;
              }

              &:nth-of-type(3) {
                top: 5.861rem;
                left: 12.555rem;
              }
            }

            &.skin span {
              &:nth-of-type(1) {
                top: 3.744rem;
                right: 12.222rem;
              }

              &:nth-of-type(2) {
                top: 6.353rem;
                right: 12.622rem;
              }

              &:nth-of-type(3) {
                top: 7.361rem;
                left: 12.222rem;
              }

              &:nth-of-type(4) {
                top: 9.022rem;
                right: 11.88rem;
              }

              &:nth-of-type(5) {
                top: 4.861rem;
                left: 12.222rem;
              }
            }

            &.blackEye span {
              &:nth-of-type(1) {
                top: 3.544rem;
                right: 12.822rem;
              }

              &:nth-of-type(2) {
                top: 2.353rem;
                left: 12.522rem;
              }
            }

            //&>img {
            //  width: 100%;
            //}
            &.colorSkin {
              position: relative;
              width: 16.22rem;
              margin: 3.056rem auto 0.667rem;

              .colorSkinBox {
                height: 1.333rem;
                line-height: 1.333rem;

                img {
                  width: 100%;
                }

                .colorSkinMarking {
                  width: 1.333rem;
                  height: 1.333rem;
                  display: inline-block;
                  position: absolute;
                  left: 0;
                  top: 1px;
                  z-index: 999;
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }


              .colorSkinText {
                margin-top: 0.833rem;
                font-size: 0.667rem;
                font-weight: bold;
                font-stretch: normal;
                line-height: 0.476rem;
                letter-spacing: 0rem;
                color: #999999;
                display: flex;
                justify-content: space-around;
              }

              .colorSkinContent {
                //width: 16.389rem;
                //height: 6.389rem;
                margin-top: 1.111rem;
                //background-color: #2b2d3d;
                border-radius: 0.556rem;
                padding: 1.15rem;
                font-size: 0.667rem;
                font-weight: normal;
                font-stretch: normal;
                line-height: 1.167rem;
                letter-spacing: 0.039rem;
                box-sizing: border-box;
                color: #c0bfbf;
                text-align: left;
                //width: 14rem;
                //height: 4.194rem;
                //font-size: 0.667rem;
                //font-weight: normal;
                //font-stretch: normal;
                //line-height: 1.167rem;
                //letter-spacing: 0.039rem;
                //color: #c0bfbf;
              }
            }
          }

          .avatar_big {
            //height: 26.5rem;
            //background-color: #333333;
            margin: 2.386278rem auto 1.110222rem;
            position: relative;
            z-index: 555;
            text-align: center;
            width: 13.597056rem;
            height: 13.597056rem;
            overflow: hidden;
            border-radius: 0.554778rem;
            border: solid 0.277722rem #999999;

            img {
              transform: translateY(calc((100% - 13.597056rem) / 2 * -1));
              //width: 20.416rem;
              cursor: pointer;
              width: 100%;

              &:nth-of-type(2) {
                position: absolute;
                left: 0;
                //top: 0;
              }
            }
          }

          .avatar {
            //width: 20.416rem;
            //height: 20.416rem;
            //margin: 3.583rem auto 1.667rem;
            //border-radius: 0.833rem;
            //border: solid 0.417rem #999999;
            //position: relative;
            //z-index: 555;
            position: absolute;
            right: 3.945rem;
            bottom: 1.445rem;
            text-align: center;
            width: 2.205rem;
            height: 2.205rem;
            overflow: hidden;
            border-radius: 50%;
            border: solid 0.1rem #999999;

            &.skin {
              right: 2.645rem;
              bottom: 0.745rem;
            }

            &.blackEye {
              right: 2.645rem;
              bottom: 0.285rem;
            }

            img {
              transform: translateY(calc((100% - 2.205rem) / 2 * -1));
              cursor: pointer;
              width: 100%;
            }
          }

          .avatar-bg {
            width: 100%;
            padding-bottom: 0.778rem;
          }
        }

        .average_probability {
          padding-top: 1.389rem;
          //background-color: #1f2331;
          //padding: 2.083rem 0 1.75rem;
          line-height: 1;

          .average_probability_title {
            text-align: center;
            font-size: 0.833rem;
            color: #ececec;
            margin-bottom: 0.555rem;
          }

          .average_probability_item {
            display: inline-block;
            border-radius: 0.667rem;
            font-size: 0.722rem;
            line-height: 1.333rem;
            background: linear-gradient(135deg, rgba(76, 196, 252, 1) 0%, rgba(88, 94, 234, 1) 100%);
            color: #ececec;
            padding: 0.111rem;
            overflow: hidden;
            margin: 0.417rem;

            span {
              display: inline-block;
              //background: rgb(59, 92, 121);
              //opacity:0.35;
              border-radius: 0.667rem;
              color: #ececec;
              padding: 0 0.533rem;
              background: #3b5c79;
            }

            //width: 6.333rem;
            //height: 2.5rem;
            //border:2px solid;
            //border-image:linear-gradient(135deg, rgba(76,196,252,1), rgba(88,94,234,1)) 10 10;
            //background:linear-gradient(135deg,rgba(76,196,252,1) 0%,rgba(88,94,234,1) 100%);
            //border-radius:15px;
            //font-size: 1rem;
            //color: #999999;
            //span{
            //  font-size: 1.25rem;
            //  color: #ececec;
            //}
          }

          .separated {
            color: white;
            margin: 0 0.333rem;
          }
        }

        .number_conditions {
          padding-top: 1.639rem;
        }

        .symptoms_nursing {
          padding: 1.639rem 1.08rem 0;
        }
        .tab-wrapper {
          position: sticky;
          top:0;
          z-index: 9999;
          background: #1f2331;
        }
        .tab{
          width: 18.61rem;
          height: 2.167rem;
          line-height: 2.167rem;
          .swiper-slide{
            height: auto;
            width: auto!important;
          }
          span {
            flex: 1;
            // padding: 0 0.5rem;
            padding: 0 0.5rem;
            font-size: 0.722rem;
            white-space: nowrap;
            text-align: center;
            letter-spacing: 0.042rem;
            color: #999999;
            &.active {
              opacity: 1;
              color: #4cc4fc;
              //&:after{
              //  content: "";
              //  width: 100%;
              //  height: 0.2rem;
              //  display: inline-block;
              //  background-color: #ffffff;
              //}
              i {
                display: inline-block;
                height: calc(100% - 0.2rem);
                border-bottom: 0.1rem solid #4cc4fc;
                //width: 2.208rem;
                //height: 0.083rem;
                //background-color: #ffffff;
              }
            }
          }
        }
        .symptoms_nursing {
          //background-color: #1f2331;
          border-bottom-right-radius: 0.555rem;
          border-bottom-left-radius: 0.555rem;

          .symptoms_described, .nursing_suggestions,
          .improve_after_nursing, .no_nursing_effect {
            color: white;
            margin-left: 2px;
            margin-top: 2px;

            strong.name {
              border-left: 2px white solid;
              padding-left: 10px;
              //margin-bottom: 1.625rem;
              font-size: 0.833rem;
              //line-height: 0.714rem;
              letter-spacing: 0.125rem;
              color: #FFFFFF;
            }

            & > div.dermalogicatext {
              margin: 0.750rem auto 1.333rem;
              font-size: 0.694rem;
              line-height: 1.333rem;
              letter-spacing: 0.1rem;
              color: #C0BFBF;
              word-break: break-word;
              //overflow: hidden;
              //text-overflow: ellipsis;
              //display: -webkit-box;
              //-webkit-box-orient: vertical;
              //-webkit-line-clamp: 6;
              .nursing_suggestions_segmentation {
                .logo_name_degree {
                  display: flex;
                  align-items: center;
                  margin: 1.08rem auto;

                  .tag_logo {
                    width: 1.875rem;
                  }

                  .tag_name {
                    color: #cdcdcd;
                    font-size: 0.833rem;
                    line-height: 0.476rem;
                    letter-spacing: 0.083rem;
                    margin: auto 0.56rem auto 0.44rem;
                  }

                  .tag_degree {
                    color: #ececec;
                    font-size: 0.667rem;
                    background-color: #c56b2a;
                    padding: 0.225rem 0.94rem 0.225rem 0.7rem;
                    border-radius: 0rem 0.656rem 0.656rem 0rem;
                  }
                }

                .advice {
                  margin: 0.750rem auto 1.333rem;
                  font-size: 0.694rem;
                  line-height: 1.333rem;
                  letter-spacing: 0.1rem;
                  color: #C0BFBF;
                  //
                  //font-weight: normal;
                  //font-stretch: normal;
                  //line-height: 1.333rem;
                  //letter-spacing: 0.067rem;
                  //margin: 0.750rem auto 1.333rem;
                  //color: #999999;
                }
              }
            }

            

            .more_mask {
              margin-top: -7rem;
              text-align: center;

              img.more_mask_img {
                width: 100%;
                margin-bottom: -.5rem;
              }

              .more_mask_button {
                margin-bottom: 1.333rem;
                font-size: 0.667rem;
                font-weight: bold;
                color: #ececec;

                img.more_mask_down {
                  width: 0.694rem;
                  margin-left: 0.306rem;
                }
              }
            }
          }
          #goodsSwiper {
            width: 16.38rem;
            // height: 10.83rem;
            background: #2E3243;
            border-radius: 10px;
            margin-bottom: 30px;
            padding: 0.55rem;
            box-sizing: border-box;
            overflow: hidden;
            .swiper-container{
              --swiper-pagination-color: #fff;
            }
            .swiper-wrapper{
              margin-bottom: 10px;
            }
            .swiper-slide{
              //  height: 9.88rem;
            }
            .swiper-pagination-bullet {
              width: 5px;
              height: 5px;
            }
          }
          .goods_list {
            // display: flex;
            // padding: 0 2px 1.389rem;
            //justify-content: center;
            //margin-left: 2px;

            .goods {
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              .goods_img {
                width: 3.88rem;
                height: 3.88rem;
                border-radius: 10px;
                overflow: hidden;
                background: #323745;
                display: flex;
                align-items: center;
                img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              .matching {
                //width: 2.1rem;
                min-width: 38px;
                //height: 2.333rem;
                padding: 0.2rem;
                background-color: #fe4e5e;
                border-radius: 0.555rem 0rem 0.555rem 0rem;
                position: absolute;
                left: 0;
                top: 0;
                .text {
                  font-size: 0.667rem;
                  line-height: 0.629rem;
                  letter-spacing: 0.033rem;
                  color: #ffffff;
                }
              }

              .goods_svg {
                right: 0;
              }

              &:not(:last-child) {
                margin-right: 1rem;
              }

              img {
                width: 100%;
                max-height: 7.556rem;
              }

              .goods_name {
                font-size: 13px;
                color: #fff;
                margin-left: 0.55rem;
                max-width: 9.83rem;
                word-wrap: break-word;
              }

              .goods_price {
                font-size: 0.778rem;
                line-height: 0.839rem;
                letter-spacing: 0.031rem;
                margin: 0.656rem 0.4rem 0.861rem;

                .discount {
                  color: #fe4e5e;
                }

                .normal_price {
                  color: #c4c4c4;
                  margin-left: 0.417rem;
                  text-decoration: line-through
                }
              }

              img.pay_icon {
                width: 1.944rem;
                position: absolute;
                right: -0.222rem;
                bottom: -0.222rem;
              }
            }
          }
        }

        .blackEyeActive {

        }

        .swiper-pagination {
          color: white;
        }

        .number_conditions {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          //background-color: #1f2331;
          border-bottom-right-radius: 0.555rem;
          border-bottom-left-radius: 0.555rem;

          .icon_num {
            display: flex;
            align-items: center;
            padding-right: 0.333rem;
            //position: absolute;
            //right: 19.083rem;
            img {
              width: 2.222rem;
            }

            .number_conditions_num {
              color: white;
              line-height: 0.953rem;
              margin-left: 0.417rem;

              span {
                display: block;
                font-size: 0.833rem;
                color: #ffffff;
                text-align: center;
              }

              .num {
                font-size: 0.667rem;
                // letter-spacing: 0.1rem;
                opacity: 0.5;
              }
            }
          }

          .number_conditions_degree {
            position: relative;
            height: 2.639rem;
            width: 11.000rem;
            //margin-left: 8.333rem;
            img {
              position: absolute;
              top: 1.361rem;
              width: 0.667rem;
            }

            .dianzan {
              left: 0
            }

            .Worse {
              left: 10.167rem
            }

            div {
              position: absolute
            }

            div.one_bar {
              width: 8.945rem;
              height: 0.389rem;
              background:linear-gradient(-90deg,rgba(17,117,164,1),rgba(76,196,252,1));
              background-blend-mode: normal,
              normal;
              border-radius: 0.083rem;
              left: 0.945rem;
              top: 1.472rem;
              z-index: 555;
            }

            div.two_bar {
              z-index: 555;
              top: 0.833rem;
              left: 0.945rem;
              width: 8.945rem;
              height: 0.139rem;
              background-color: #ffffff;
            }

            .one_box, .two_box {
              //top: 0;
              width: 2.867rem;
              height: 2.528rem;
              border-left: dashed 2px #555555;
              border-right: dashed 2px #555555;
            }

            .one_box {
              left: 0.833rem;
            }

            .two_box {
              left: 6.833rem;
            }

            .text,.vl-text {
              left: 0.945rem;
              width: 8.945rem;
              font-size: 0.667rem;
              letter-spacing: 0.1rem;
              color: #ffffff;
              opacity: 0.5;

              span {
                display: inline-block;
                width: 33.3333%;
                text-align: center;
              }
            }
            .vl-text{
              top:-10px;
            }

            .box {
              z-index: 999;
              left: 1.986rem;
              width: 0.778rem;
              height: 0.778rem;
              border: solid 0.133rem #fafafa;
              border-radius: 50%;
              top: 1.167rem;
            }
          }
        }
      }
    }
  }

  footer {
    //height: 4.167rem;
    //line-height: 4.167rem;
    //background-image: url("../img/DJM-Logo@2x.png");
    //background-size: 100% 100%;
    //background-repeat: no-repeat;
    //background-position: center;
    //font-family: SourceHanSansCN-Regular;
    margin-bottom: 2.828rem;
    font-size: 0.833rem;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0.083rem;
    color: #ececec;
    text-align: center;
    cursor: pointer;
    position: relative;

    &.active {
      opacity: 0.8;
      margin-top: 3rem;
    }

    img {
      width: 4.222rem;
      margin: 1.667rem auto 0;
    }

    span {
      //width: auto;
      //position: absolute;
      //top: 1.945rem;
      //left: 0;
      margin-top: 10px;
      margin-left: 50%;
      display: block;
      transform: translateX(-50%);
      //white-space: nowrap;
      //font-family: SourceHanSansCN-Regular;
      font-size: .667rem;
      font-weight: bold;
      font-stretch: normal;
      line-height: 0.555rem;
      letter-spacing: 0rem;
      width: 80%;
      min-width: 18.611rem;
      color: #4d546e;
      line-height: 25px;
    }
  }
}

.En, .en {
  #Share_pic {
    //z-index:999;
    height: 27.167rem - 1.639rem;
    background-size: 100% 27.167rem;
    background-position: bottom -1.639rem right 0;

    .top .left {
      .title {
        letter-spacing: 0.15rem;
      }

      .score {
        margin-top: 0;

        .text {
          max-width: none;
          //margin-right: 0.5rem;
          &::after {
            content: ':';
          }
        }
      }

      .beat {
        letter-spacing: 0
      }
    }

    .center {
      margin-top: 1.9rem;
    }

    .footer.isShare {
      width: calc(100% - 4rem);
    }
    .footer{
      .Badge-icon{
        margin-left: 2.11rem;
      }
    }
  }
}

.degree_icon {
  width: 0.889rem;
  height: 0.889rem;
  //background-color: rgb(199, 44, 59);
  //border-radius: 50%;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  img {
    width: 100%;
    //width: 0.667rem;
    //height: 0.667rem;
  }
}

.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

#Share_pic {
  display: block;
  width: 18.833rem;
  height: 27.167rem;
  margin: auto;
  position: relative;
  z-index: -999;
  background-color: #000;
  background-size: 100% 100%;
  background-image: url("../../static/img/Bounced_Flow@2x.png");

  .Bounced_Flow, .avatar {
    position: absolute;
  }

  .Bounced_Flow {
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1
  }

  .top {
    display: flex;
    position: relative;

    .left {
      margin-left: 1.945rem;

      .title {
        //font-family: FZLTCHJW--GB1-0;
        font-size: 1.111rem;
        line-height: 0.976rem;
        color: #ececec;
        margin-top: 1.528rem;
      }

      .time {
        //font-family: FZLTH;
        height: 0.476rem;
        min-height: 0.476rem;
        font-size: 0.555rem;
        line-height: 0.476rem;
        color: #7d8498;
        margin-top: 0.639rem;
      }

      .score {
        margin-top: 1.39rem;

        span {
          display: inline-block
        }

        .text {
          //width: 1.833rem;
          //font-family: AdobeHeitiStd-Regular;
          min-width: 1.833rem;
          max-width: 4.25rem;
          font-size: 0.778rem;
          line-height: 0.893rem;
          letter-spacing: 0.117rem;
          color: #ececec;
        }

        .value {
          width: 3.667rem;
          //height: 2.458rem;
          //font-family: FZLTCHJW--GB1-0;
          font-size: 1.929rem;
          font-weight: normal;
          font-stretch: normal;
          //line-height: 0.714rem;
          letter-spacing: 0.117rem;
          color: #ececec;
          text-indent: 0.417rem;
        }
      }

      .beat {
        //max-width: 10rem;
        margin-top: .667rem;
        //font-family: AdobeHeitiStd-Regular;
        font-size: .667rem;
        height: 0.667rem;
        letter-spacing: 0.081rem;
        color: #a0a0a0;
      }
    }

    .right {
      width: 10.00rem;
      height: 10.00rem;
      position: absolute;
      right: 0;
      overflow: hidden;
      //top: 1.333rem;
    }
  }

  .center {
    margin-top: 2.16rem;
    //overflow: hidden;
    .avatar {
      width: 3.361rem;
      height: 3.333rem;
      border: solid 0.055rem #4f536c;
      margin: -0.972rem 0 0 1.333rem;
      border-radius: 50%;
    }

    .score_text {
      text-align: right;

      span {
        display: inline-block;
        font-size: 0.555rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.476rem;
        color: #fafafa;
        padding: 0.928rem 0;
        //margin-left: 9.006rem;
        i{
          content: '';
          width: 0.361rem;
          height: 0.361rem;
          display: inline-block;
          border-radius: 50%;
          background-color: #4cc4fc;
          margin-right: 0.278rem;
        }

        &.national {
          color: #7e7e7e;
          margin: 0 1.222rem;

          i{
            background-color: #7e7e7e;
          }
        }
      }
    }

    .score_process_box {
      margin: 1.695rem auto 0;
      display: flex;
      justify-content: space-around;
      //padding: 2.542rem 0 0 3.417rem;
    

    .score_process {
      width: 2.74rem;
      height: 8.000rem;
      text-align: center;
      //margin-right: 0.222rem;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }

      .value {
        font-size: 0.778rem;
        //line-height: 0.714rem;
        color: #ffffff;
        margin-bottom: 0.472rem;
      }

      .top_process {
        position: relative;
        width: 100%;
        height: 4.667rem;
        margin-bottom: 0.445rem;

        .the, .national {
          height: 4.667rem;
          background-color: #3c3c3c;
        }

        .the, .the_bg, .national, .national_bg {
          position: absolute;
          width: 0.389rem;
          border-radius: 0.184rem 0.184rem 0rem 0rem;
          bottom: 0;
        }

        .the_bg {
          background-color: #4cc4fc;
        }

        .national_bg {
          background-color: #fafafa;
          opacity: 0.35;
        }

        .the, .the_bg {
          left: 0.955rem;
        }

        .national, .national_bg {
          left: 1.49333rem;
        }
      }

      .text {
        font-size: 0.611rem;
        //letter-spacing: 0.092rem;
        color: #ffffff;
      }
    }
    }
  }

  .footer {
    display: flex;
    height: 4.500rem;
    position: relative;
    //&.noShare{
    .qc {
      border: 1px solid #eee;
      border-radius: 5px;
      width: 2.778rem;
      height: 2.778rem;
      margin: 0.861rem 0 0 1.667rem;
      //background-image: url("../img/H5_Measuring_skin.png");
      //background-size: 90% 90%;
      /* padding: 2px; */
      //background-repeat: no-repeat;
      //background-position: center;
    }

    figure {
      //margin: 1.055rem 0 0 0.528rem;
      margin-left: 0.528rem;
      .text {
        //width: 10.5rem;
        //height: 1.208rem;
        //font-family: FZLTH;
        font-size: 0.833rem;
        font-weight: bold;
        //font-stretch: normal;
        //letter-spacing: 0.05rem;
        margin-bottom: 0.361rem;
        color: #C0C2C5;
      }

      .logo {
        height:0.75rem;
        //width: 4.695rem;
        //height: 1.125rem;
        //opacity: 0.25;
        //background-image: url("../img/DJM-Logo@2x.png");
        //background-size: 100% 100%;
        //background-repeat: no-repeat;
      }

      //}
    }

    .Badge-icon {
      margin: 0.72rem 0.8rem auto 3.97rem;
      width: 3.06rem;
      height: 3.06rem;
    }

    &.isShare {
      width: 11.394rem;
      height: 3.056rem;
      overflow: hidden;
      margin: 0.722rem auto 0;

      .qc {
        display: none;
      }

      .Badge-icon {
        left: 0;
        width: 3.056rem;
      }

      figure {
        margin: auto auto auto 3.861rem;

        .text {
          color: #A0A0A0;
        }

        .logo {
          width: 2.5rem;
        }
      }
    }
  }
}

.pinch-zoom-container {
  height: 100vh;
}

.share_box {
  position: relative;
  width: 18.611314722rem;
  margin: auto;
  overflow: hidden;

  .Bounced_Delete {
    width: 2.333rem;
    height: 2.333rem;
    z-index: 999;
    position: absolute;
    right: 0;
  }

  img.canvas_img {
    width: 100%;
    border-top-left-radius: 1.333rem;
    border-bottom-right-radius: 1.333rem;
  }
  .skin_question_avatar_img {
    width: 100%;

    &:nth-of-type(3) {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .promptWords {
    font-size: 0.833rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    color: #ececec;
  }
}

.bouncedPrompt {
  position: absolute;
  background-color: white;
  padding: .667rem;
  border-radius: .667rem;
  box-sizing: border-box;
  font-size: 0.800rem;
  line-height: 1.3;
  //overflow-y: scroll;
  //overflow-x: visible;
  //letter-spacing: 0.2rem;
  z-index: 999;
  min-height: 3rem;

  div.degree_icon_box {
    display: flex;
    align-items: center;

    &:nth-of-type(even) {
      margin: .5rem 0;
    }

    .degree_icon {
      width: 0.889rem;
      margin: 0.5rem 0.5rem 0.5rem 0
    }

    .text {
      flex: 1
    }
  }

  div.text_box {
    overflow-y: scroll
  }

  &:after {
    content: "";
    transform: rotate(45deg);
    position: absolute;
    top: 1rem;
    right: -0.333rem;
    display: block;
    width: .667rem;
    height: .667rem;
    background-color: white;
  }
}
.PhotoView__Photo{
  background-size: 100% 100%;
}
.PhotoView__Photo.blackhead{
  background-size: 100% 100%;
}
//@keyframes strokeDasharray{
//  0%{ stroke-dasharray: 0,345.625; }
//  //25%{ top:50%; }
//  //50%{ top:100%; }
//  //75%{ top:50%; }
//  100%{ stroke-dasharray: 350,650; }
//}

.hide{
  display: none;
}
.bgColor {
  background: transparent;
}

.wrapper-active{
  height: auto;
}
.swiper-slide{height:10px}  // 高度设置一个最基础的就可以

.swiper-slide-active { height:auto}
.PhotoView-SlideWrap {
  z-index: 40000;
}


.perview_bg_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.layui-m-layerchild{
  background: transparent;
}

// ai画图
.canvans_box {
  width: 20.7rem;
  height: 27.6rem;
  overflow: hidden;
  position: absolute;
  left:-99rem;
  img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
    width: auto;  
    height: auto;
    top:0;
    left:0;
  }
  i{
    z-index: 2;
    position: absolute;
    display: block;
    width: 1.5px;
    height: 1.5px;
    border-radius: 50%;
  }
  .pore {
    background-color: rgba(255,54,99,255);
  }
  .blackhead{
    background-color: rgba(0,100,0,255);
  }
}